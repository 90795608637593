import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: "en",
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources: {
			en: {
				translation: {
					popupText: {
						span1: "Your account",
						span2: "is not synced with the software, see the panel below,it should reach",
						span3: "before you can use it. Synchronization is",
						span4: "complete! Make a deposit to increase your interest",
					},
					popupTitle: "Important information",
					popupButtons: {
						b1: "MAKE DEPOSIT",
						b2: "HELP",
					},
					cardTitle: "Odds history:",
					cardDescription: 'CLICK THE "START" BUTTON, TO GET THE ODDS FOR THE NEXT ROUND',
					cardButton: "START",
					cardButton2: "How to improve synchronization?",
					cardPlaner: {
						text: "Prediction with a chance of success of",
					},
					description: {
						span1: "Your account ",
						span2:
							"is not fully synchronized with the software, the percentage should reach 100%, then the accuracy of the signals will be as high as possible. Synchronization is",
						span3: "complete!",
					},
					login: {
						message: { phone: "Incorrect phone number format", email: "Email format is not valid" },
						title: "Registration",
						button: "CONFIRM",
					},
					idPopup: {
						v1: {
							text: "The entered ID was not found",
							title: "Important information",
							buttons: {
								b1: "TRY AGAIN",
								b2: "Where can I get the ID?",
							},
						},
						v2: {
							text: {
								s1: "The software does not sync with your account. To complete the progress, the total amount of deposits required is",
								s2: ", you need to make a deposit of",
							},
							title: "Important information",
							buttons: {
								b1: "MAKE DEPOSIT",
								b2: "HELP",
								b3: "Why do I need it?",
							},
						},
					},
					id: { title: "Enter your ID below", button: "CONFIRM", video: "Where can I get the ID?" },
					vining: {
						title: "Winnings in real time:",
					},
					card3_popup1: {
						title: "Signal details",
						text: "Was the signal successful?",
						buttons: {
							b1: "SUCCESSFULLY",
							b2: "LOSS",
						},
					},
					card3_popup2: {
						title: "Signal details",
						text: "To increase your chance of winning, you need your synchronization percentage to increase, this can be done by making a deposit",
						buttons: {
							b1: "MAKE DEPOSIT",
							b2: "HELP",
						},
					},
					card3_popup3: {
						title: "Signal details",
						text: "To increase your chance of winning, you need your synchronization percentage to increase, this can be done by making a deposit",
						buttons: {
							b1: "MAKE DEPOSIT",
							b2: "HELP",
						},
					},
					makeDeposit: "How to make a deposit?",
					signalStory: {
						title: "Your successful signals",
						noSignals: 'Receive a signal using the "Start" button',
					},
					limitSignal:
						"You have a signal limit for today, come back tomorrow! To receive more signals, synchronize the program 100%, the percentage can be increased by making a deposit.",
					arrow: "More about the software",
					revievs: {
						title_1: "What does it look like?",
						title_2: " What does our software do?",
						title_3: "Video reviews",
						title_4: "reviews",
						step_1: "Synchronize the software with your account",
						step_2: "Predicts odds for the Aviator game",
						step_3: "Helps increase your profits",
					},
					buttonPlay: "PLAY",
					watingForRaund: "Waiting for the round",
					currentCoef: "THE CURRENT COEFFICIENT IS IN PLAY",
					roundOver: "THE ROUND IS OVER",
					flewOff: "FLEW OFF",
				},
			},
			hi: {
				translation: {
					popupText: {
						span1: "Aapka account",
						span2: "software ke saath sync nahi hai, neeche diye gaye panel ko dekhein, yeh",
						span3: "tak pahunchna chahiye pehle aap ise use kar sakein. Synchronization",
						span4: "complete! Apne interest badhane ke liye deposit karein.",
					},
					popupTitle: "Mahatvapurn jaankari",
					popupButtons: {
						b1: "DEPOSIT KAREIN",
						b2: "MADAD",
					},
					cardTitle: "Odds histri:",
					cardDescription: 'AGLE ROUND KE ODDS PANE KE LIYE "START" BUTTON KO CLICK KARO',
					cardButton: "START",
					cardButton2: "Synchronization kaise sudharein?",
					cardPlaner: {
						text: "Safalta ki sambhavnayein ke saath prediction",
					},
					description: {
						span1: "Aapka account ",
						span2:
							"poori tarah se software ke saath synchronize nahi hai, percentage 100% tak pahunchna chahiye, tab signals ki accuracy sabse zyada hogi. Synchronization",
						span3: "complete!",
					},
					login: {
						message: { phone: "Galat phone number format", email: "Email format valid nahi hai" },
						title: "Registration",
						button: "CONFIRM KAREIN",
					},
					idPopup: {
						v1: {
							text: "Entered ID nahi mila",
							title: "Mahatvapurn jaankari",
							buttons: {
								b1: "PHIRSE KOSHISH KARO",
								b2: "ID kaha milega?",
							},
						},
						v2: {
							text: {
								s1: "Software aapke account ke saath sync nahi ho raha hai. Progress complete karne ke liye, required deposits ka total amount",
								s2: ", hai, aapko deposit karna hoga",
							},
							title: "Mahatvapurn jaankari",
							buttons: {
								b1: "DEPOSIT KAREIN",
								b2: "MADAD",
								b3: "Mujhe iski zarurat kyun hai?",
							},
						},
					},
					id: {
						title: "Neeche apna ID daalein",
						button: "CONFIRM KAREIN",
						video: "ID kaha milega?",
					},
					vining: {
						title: "Real time mein winnings:",
					},
					card3_popup1: {
						title: "Signal details",
						text: "Kya signal safal raha?",
						buttons: {
							b1: "SAFALTA",
							b2: "LOSS",
						},
					},
					card3_popup2: {
						title: "Signal details",
						text: "Aapki jeet ki sambhavnayein badhane ke liye, aapka synchronization percentage badhna chahiye, yeh deposit karke kiya ja sakta hai",
						buttons: {
							b1: "DEPOSIT KAREIN",
							b2: "MADAD",
						},
					},
					card3_popup3: {
						title: "Signal details",
						text: "Aapki jeet ki sambhavnayein badhane ke liye, aapka synchronization percentage badhna chahiye, yeh deposit karke kiya ja sakta hai",
						buttons: {
							b1: "DEPOSIT KAREIN",
							b2: "MADAD",
						},
					},

					makeDeposit: "Deposit kaise karein?",
					signalStory: {
						title: "Aapke safal signals",
						noSignals: '"Start" button ka use karke signal prapt karein',
					},
					limitSignal:
						"Aaj ke liye aapka signal limit khatam ho gaya hai, kal wapas aayein! Zyada signals prapt karne ke liye, program ko 100% synchronize karein, percentage deposit karke badhaya ja sakta hai.",
					arrow: "Software ke baare mein zyada jaankari",
					revievs: {
						title_1: "Yeh kaisa dikhta hai?",
						title_2: " Hamara software kya karta hai?",
						title_3: "Video reviews",
						title_4: "reviews",
						step_1: "Apne account ke saath software ko synchronize karein",
						step_2: "Aviator game ke odds predict karta hai",
						step_3: "Aapke profits badhane mein madad karta hai",
					},
					buttonPlay: "PLAY",
					watingForRaund: "gol ki prateeksha ki ja rahi hai",
					currentCoef: "VARTMAN GUNANK KHEL ME HAI",
					roundOver: "RAUND SAMAPT HO GYA HAI",
					flewOff: "UDANA",
				},
			},
		},
	});

export default i18n;

// translation: {
//   popupText: {
//     span1: "आपका खाता",
//     span2: "सॉफ़्टवेयर के साथ सिंक्रनाइज़ नहीं हुआ है, नीचे दिए गए बार को देखें,यह",
//     span3: "तक पहुंचना चाहिए, आप इसका उपयोग कर सकेंगे। सिंक्रोनाइज़ेशन",
//     span4: "पूर्ण है!",
//   },
//   popupTitle: "महत्वपूर्ण सूचना",
//   popupButtons: {
//     b1: "जमा कर दो",
//     b2: "मदद",
//   },
//   cardTitle: "निम्नलिखित संभावनाएँ",
//   cardDescription: '"प्रारंभ" बटन पर क्लिक करें,अगले दौर के लिए ऑड्स प्राप्त करने के लिए',
//   cardButton: "शुरू",
//   cardButton2: "सिंक्रोनाइज़ेशन कैसे सुधारें?",
//   cardPlaner: {
//     text: "की सफलता की संभावना के साथ भविष्यवाणी",
//   },
//   description: {
//     span1: "आपका खाता",
//     span2:
//       "सॉफ्टवेयर के साथ पूरी तरह से सिंक्रनाइज़ नहीं है, प्रतिशत 100% तक पहुंचना चाहिए, फिर संकेतों की सटीकता यथासंभव अधिक होगी। सिंक्रोनाइज़ेशन",
//     span3: "पूर्ण है!",
//   },
//   login: {
//     message: { phone: "ग़लत फ़ोन नंबर प्रारूप", email: "ईमेल प्रारूप मान्य नहीं है" },
//     title: "पंजीकरण",
//     button: "नीचे अपनी आईडी दर्ज करें",
//   },
//   idPopup: {
//     v1: {
//       text: "दर्ज की गई आईडी नहीं मिली.",
//       title: "महत्वपूर्ण सूचना",
//       buttons: {
//         b1: "पुनः प्रयास करें",
//         b2: "मुझे आईडी कहां मिल सकती है?",
//       },
//     },
//     v2: {
//       text: {
//         s1: "सॉफ़्टवेयर आपके खाते के साथ समन्वयित नहीं होता है. प्रगति को पूरा करने के लिए, आवश्यक जमा राशि की कुल राशि",
//         s2: " है, आपको",
//       },
//       title: "महत्वपूर्ण सूचना",
//       buttons: {
//         b1: "जमा कर दो",
//         b2: "मदद",
//         b3: "मुझे इसकी ज़रूरत क्यों है?",
//       },
//     },
//   },
//   id: {
//     title: "नीचे अपनी आईडी दर्ज करें",
//     button: "नीचे अपनी आईडी दर्ज करें",
//     video: "मुझे आईडी कहां मिल सकती है?",
//   },
//   vining: {
//     title: "वास्तविक समय में जीत:",
//   },
//   card3_popup1: {
//     title: "सिग्नल विवरण",
//     text: "क्या सिग्नल सफल रहा?",
//     buttons: {
//       b1: "सफलतापूर्वक",
//       b2: "नुकसान",
//     },
//   },
//   card3_popup2: {
//     title: "सिग्नल विवरण",
//     text: "जीतने की संभावना बढ़ाने के लिए, आपको अपने सिंक्रोनाइज़ेशन प्रतिशत को बढ़ाने की आवश्यकता है, यह जमा करके किया जा सकता है",
//     buttons: {
//       b1: "जमा कर दो",
//       b2: "मदद",
//     },
//   },
//   card3_popup3: {
//     title: "सिग्नल विवरण",
//     text: "जीतने की संभावना बढ़ाने के लिए, आपको अपने सिंक्रोनाइज़ेशन प्रतिशत को बढ़ाने की आवश्यकता है, यह जमा करके किया जा सकता है",
//     buttons: {
//       b1: "जमा कर दो",
//       b2: "मदद",
//     },
//   },
//   makeDeposit: "जमा कैसे करें?",
//   signalStory: {
//     title: "आपके सफल संकेत",
//     noSignals: '"प्रारंभ" बटन का उपयोग करके सिग्नल प्राप्त करें',
//   },
//   limitSignal:
//     "आपके पास आज के लिए सिग्नल की सीमा है, कल वापस आएँ! अधिक सिग्नल प्राप्त करने के लिए, प्रोग्राम को 100% सिंक्रोनाइज़ करें, जमा करके प्रतिशत बढ़ाया जा सकता है।",
//   arrow: "सॉफ़्टवेयर के बारे में अधिक जानकारी",
//   revievs: {
//     title_1: "यह किस तरह का दिखता है?",
//     title_2: " हमारा सॉफ्टवेयर क्या करता है?",
//     title_3: "वीडियो समीक्षाएँ",
//     title_4: "समीक्षा",
//     step_1: "सॉफ़्टवेयर को अपने खाते के साथ सिंक्रनाइज़ करें",
//     step_2: "एविएटर गेम के लिए संभावनाओं की भविष्यवाणी करता है",
//     step_3: "आपके मुनाफ़े को बढ़ाने में मदद करता है",
//   },
//   buttonPlay: "खेल",
// },

import React from "react";
import css from "../styles/Card.module.scss";
import styles from "../styles/App.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const Card1 = ({ coefficients, setStep, lang }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={css.card}>
			<div className={css.cardBody}>
				<div className={css.loaderContainer}>
					<svg
						className={css.loader}
						width="130"
						height="130"
						xmlns="http://www.w3.org/2000/svg"
					>
						<image
							className="load"
							href="load.svg"
							height="130"
							width="130"
							color="black"
						/>
					</svg>
				</div>
				<div className={css.info}>
					<p
						className={classNames(
							i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium",
							css.text,
						)}
					>
						{t("cardDescription")}
					</p>
					<div style={{ width: "100%", display: "flex", gap: "10px" }}>
						<button
							className={classNames("enBold", styles.button, styles._small)}
							onClick={() => setStep(2)}
						>
							{t("cardButton")}
						</button>
						<button
							className={classNames(
								styles.button,
								styles._small,
								styles._blue,
								i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
							)}
							style={{ backgroundColor: "#FFD700", color: "black" }}
						>
							{t("buttonPlay")}
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card1;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import css from "../styles/Card.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

const min = 1.1;
const max = 2.56;

const Card2 = ({
	firstDuration,
	coefficients,
	currentCoef,
	setStep,
	lang,
	oldCoef,
	setOldCoef,
	setCoefficients,
}) => {
	const { t, i18n } = useTranslation();

	useEffect(() => {
		const timeout = setTimeout(() => {
			const randomNum = Math.random() * (max - min) + min;
			const newCoefficients = [...coefficients, randomNum.toFixed(2)];
			setCoefficients(newCoefficients);
			const newOldCoef = [...oldCoef];
			setOldCoef([...newOldCoef, currentCoef]);
			setStep(3);
		}, [firstDuration]);
		return () => clearTimeout(timeout);
	}, []);

	return (
		<div className={css.card}>
			<div className={css.cardBody}>
				<div className={css.loaderContainer}>
					<svg
						className={css.loader}
						width="130"
						height="130"
						xmlns="http://www.w3.org/2000/svg"
					>
						<image
							className="load"
							href="load.svg"
							height="130"
							width="130"
							color="black"
						/>
					</svg>
				</div>
				<div className={css.info}>
					<div className={classNames(css.enBold, css.coef)}>
						<span>x{coefficients[currentCoef]}</span>
					</div>
					<p
						className={classNames(i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium", css.text)}
						style={{ marginBottom: "0px", fontSize: "18px" }}
					>
						{t("watingForRaund")}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Card2;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import css from "../styles/Vining.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import numeral from "numeral";

const Vining = () => {
	const { t, i18n } = useTranslation();
	const [elWidth, setElWidth] = useState();
	const [translate, setTranslate] = useState(0);
	const [elements, setElements] = useState([]);

	useEffect(() => {
		createElement();
		createElement();
		const lifeline = document.getElementById("lifeline");
		const width = lifeline.offsetWidth / 2;
		scrollElements(width);
	}, []);

	const getRandomInt = (min, max) => {
		min = Math.ceil(min);
		max = Math.floor(max);
		return Math.floor(Math.random() * (max - min + 1)) + min;
	};

	const getRandomFloat = (min, max) => {
		return (Math.random() * (max - min) + min).toFixed(1);
	};

	const getRandomFloat2 = (min, max) => {
		return (Math.random() * (max - min) + min).toFixed(2);
	};

	const createElement = () => {
		const minRange = 28475674;
		const maxRange = 83746217;
		const minRange2 = 1.1;
		const maxRange2 = 17.2;
		const minRange3 = 21.1;
		const maxRange3 = 25456.98;
		const randomId = getRandomInt(minRange, maxRange);
		const randomCoef = getRandomFloat(minRange2, maxRange2);
		const randomSum = getRandomFloat2(minRange3, maxRange3);

		const newElements = elements;

		newElements.push({ id: randomId, coef: randomCoef, sum: (randomSum * 95).toFixed(0) });
		setElements(newElements);
	};

	const scrollElements = widthChangePos => {
		let trans = 0;
		setInterval(() => {
			trans = trans + widthChangePos;
			setTranslate(trans);
			createElement();
		}, 2500);
	};

	return (
		<div className={css.container}>
			<div className={classNames(i18n.resolvedLanguage === "en" ? "enBold" : "hinBold", css.title)}>
				{t("vining.title")}
			</div>
			<div className={classNames(css.lineWrapper)}>
				<div
					className={classNames(css.line)}
					id="lifeline"
					style={{ transform: `translateX(-${translate}px)` }}
				>
					{elements.map((el, i) => {
						return (
							<div
								className={classNames(css.item)}
								key={`${el.id}${el.sum}${el.coef}${i}`}
							>
								<span className={css.id}>{el.id}</span> won{" "}
								<span className={css.sum}>INR {numeral(el.sum).format('0,0')}</span>{" "}
								<span className={css.coef}>X{el.coef}</span>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default Vining;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import css from "../styles/App.module.scss";
import styles from "../styles/Card.module.scss";
import classNames from "classnames";

const Config = () => {
	const navigate = useNavigate();

	const [data, setData] = useState([
		{ value: "" },
		{ value: "" },
		{ value: "" },
		{ value: "" },
		{ value: "" },
	]);

	useEffect(() => {
		(async () => {
			const res = await axios.get("https://aviator.raulnk.com/api/get");
			const newCoeffs = res?.data?.map(el => {
				return { value: +el.value };
			});

			setData(newCoeffs);
		})();
	}, []);

	const sendData = async () => {
		const newData = JSON.stringify(data);
		const res = await axios.post("https://aviator.raulnk.com/api/update", newData);
		if (res?.data?.success) {
			navigate("/");
		}
	};

	return (
		<div className={css.container}>
			<div className={css.config}>
				<div className={css.title} />
				<div className={css.form}>
					{data.map((el, index) => {
						return (
							<label className={css.label} htmlFor="" key={`${index}`}>
								<span className={css.labelText}>{index + 1}:</span>
								<input
									className={css.input}
									type="text"
									value={el.value}
									onChange={e => {
										const inputValue = e.target.value;
										const onlyNumbers = inputValue.replace(/[^0-9.]/g, "");
										const newData = [...data];
										newData[index].value = onlyNumbers;
										setData(newData);
									}}
								/>
							</label>
						);
					})}
					<button
						className={classNames(styles.enBold, styles.buttonStart)}
						style={{ marginTop: "30px" }}
						onClick={sendData}
					>
						SAVE
					</button>
				</div>
			</div>
		</div>
	);
};

export default Config;

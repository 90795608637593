import React, { useEffect, useState } from "react";
import css from "../styles/Card.module.scss";
import styles from "../styles/App.module.scss";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Vining from "./Vining";

const CardContainer = ({ children, lang, setLang, coefficients, oldCoef, currentCoef }) => {
	const { t, i18n } = useTranslation();

	return (
		<div className={css.container}>
			<label
				className={classNames(css.checkbox, css.enMedium)}
				onClick={() => {
					lang === "en" ? setLang("hi") : setLang("en");
				}}
			>
				<div
					className={classNames(
						css.switcher,
						lang === "en" && css._pos1,
						lang === "hi" && css._pos2,
					)}
				/>
				<span className={classNames(css.lang, css._left, lang === "en" && css._active)}>eng</span>
				<span className={classNames(css.lang, css._right, lang === "hi" && css._active)}>hi</span>
			</label>
			<h2
				className={classNames(i18n.resolvedLanguage === "en" ? "enMedium" : "hinMedium", css.title)}
			>
				{t("cardTitle")}
			</h2>
			<div className={classNames(css.enBold, css.coefficients)}>
				{coefficients.map((el, index) => {
					return (
						<React.Fragment key={`${el}-${index}`}>
							{!oldCoef.includes(index) && (
								<div
									style={index === 0 ? { display: "none" } : {}}
									className={classNames(
										css.coef,
										+el >= 1.0 && +el < 2 && css._1,
										+el >= 2.0 && +el < 3 && css._2,
										+el >= 3.0 && css._3,
									)}
								>
									<span>{el}</span>
								</div>
							)}
						</React.Fragment>
					);
				})}
			</div>
			<Vining />
			{children}
			<div className={css.progressWrapper}>
				<div className={classNames(css.progress2, css.progress_moved)}>
					<div className={css.progress_bar2}>
						<span className={css.lightning}></span>
					</div>
					<div className={classNames(css.enBold, css.interest)}>100.00%</div>
				</div>
			</div>
			<button
				className={classNames(
					styles.button,
					styles._small,
					styles._blue,
					i18n.resolvedLanguage === "en" ? "enBold" : "hinBold",
					styles._gray,
				)}
				style={{ marginTop: "30px" }}
			>
				{t("card3_popup3.buttons.b2")}
			</button>
		</div>
	);
};

export default CardContainer;
